<template>
  <b-nav small align="right" id="top-navigation" class="top-navigation">
    <b-nav-item>
      <router-link to="/">
        {{ "main" | language("navigation") }}
      </router-link>
    </b-nav-item>
    <b-nav-item>
      <router-link to="/contacts">
        {{ "contacts" | language("navigation") }}
      </router-link>
    </b-nav-item>
    <!-- Переключение языков -->
    <b-nav-item-dropdown
      id="top-nav-dropdown"
      :text="getLanguages[getLanguage]"
      toggle-class="nav-link-custom"
      right
    >
      <b-dropdown-item
        v-for="(valueLang, keyLang) in getLanguages"
        :key="keyLang"
        @click="toggleLang(keyLang)"
      >
        {{ valueLang }}
      </b-dropdown-item>
    </b-nav-item-dropdown>
  </b-nav>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters(["getLanguage", "getLanguages"]),
  },
  methods: {
    ...mapActions(["changeLanguage"]),
    toggleLang(keyLang) {
      // установка языка в localstorage
      localStorage.setItem("lang", keyLang);
      this.changeLanguage(keyLang);
    },
  },
  mounted() {},
};
</script>

<style scoped>
.top-navigation {
  background-color: var(--main-bg-color);
}
</style>