import store from '../store'
import title from '@/languages/MainLayout/title.json'
import navigation from '@/languages/MainLayout/navigation.json'
import headerBanner from '@/languages/MainLayout/headerBanner.json'
import sidebar from '@/languages/MainLayout/sidebar.json'
import links from '@/languages/MainLayout/links.json'
import footer from '@/languages/MainLayout/footer.json'

const components = {
    title,
    navigation,
    headerBanner,
    sidebar,
    links,
    footer
}

export default function languageFilter(key, component) {

    const language = store.getters.getLanguage || 'en'
    if (components[component][key]) {
        // 2021/12/28 AlexeyZ debug
        //console.log(JSON.parse(JSON.stringify(key)));
        //console.log(JSON.parse(JSON.stringify(component)));
        //console.log(JSON.parse(JSON.stringify(components[component][key])));
        return components[component][key][language]
    }
    return `[Language filter error]: key "${key}" not found`
}
