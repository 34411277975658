<template>
  <div id="main-layout-sidebar-header">
    <h2>{{ "HeaderSidebar" | language("sidebar") }}</h2>

    <ul id="main-layout-sidebar-menu">
      <li v-for="link in filterMenuItems" :key="`sidebar-${link.name}`">
        <router-link
          v-if="link.type === 'path'"
          :to="link.link"
          :style="link.styles"
        >
          <i class="mdi mdi-link"></i>
          {{ link.name | language("links") }}
        </router-link>

        <a
          v-if="link.type === 'outpath'"
          :href="link.link"
          :style="link.styles"
        >
          <i class="mdi mdi-link"></i>
          {{ link.name | language("links") }}
        </a>
        <a
          v-if="link.type === 'download-link'"
          :href="filterLink(link.link, 'links')"
          download
          :style="link.styles"
        >
          <i class="mdi mdi-download"></i>
          {{ link.name | language("links") }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import languageFilter from "../../filters/language.filter";

export default {
  computed: {
    ...mapGetters(["getSidebarMenuItems", "getLanguage"]),
    filterMenuItems() {
      return this.getSidebarMenuItems.filter((link) => {
        // 2021/12/28 DEBUG block by AlexeyZ
        //{
        //console.log(JSON.parse(JSON.stringify(link)));
        //}
        // end of DEBUG block

        if (link.isActive[this.getLanguage]) {
          return link;
        }
      });
    },
  },
  methods: {
    filterLink(link, component) {
      return languageFilter(link, component);
    },
  },
};
</script>

<style scoped>
#main-layout-sidebar-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: left;
}
#main-layout-sidebar-menu li {
  padding: 5px 0;
}
#main-layout-sidebar-menu a {
  text-decoration: none;
  display: grid;
  /* grid-auto-flow: column; */
  grid-template: 1fr / auto 1fr;
}
#main-layout-sidebar-menu a i {
  padding-top: 2px;
}
</style>
