<template>
  <div class="main-layout">
    <vue-headful
      :title="filterTitle('mainTitle', 'title')"
      :description="filterTitle('mainTitleDescription', 'title')"
    />
    <b-container fluid="lg" class="main-layout-container">
      <TopNavigation />
      <HeaderBanner />
      <NavBar />
      <b-row class="main-layout-content">
        <b-col
          order-lg="2"
          cols="12"
          lg="4"
          class="main-layout-sidebar-container"
        >
          <SideBar />
        </b-col>
        <b-col
          order-lg="1"
          cols="12"
          lg="8"
          class="main-layout-pages-container"
        >
          <router-view />
        </b-col>
      </b-row>
      <Footer />
    </b-container>
  </div>
</template>

<script>
import TopNavigation from "@/components/TopNavigation";
import HeaderBanner from "@/components/HeaderBanner";
import NavBar from "@/components/NavBar";
import SideBar from "@/components/SideBar/SideBar";
import Footer from "@/components/Footer";
import languageFilter from "@/filters/language.filter";

export default {
  components: {
    TopNavigation,
    HeaderBanner,
    NavBar,
    SideBar,
    Footer,
  },
  methods: {
    filterTitle(link, component) {
      return languageFilter(link, component);
    },
  },
};
</script>

<style scoped>
.main-layout-sidebar-container {
  padding: 0;
}
.main-layout-pages-container {
  padding: 20px;
}

.main-layout-container {
  max-width: 1000px;
  padding: 0;
}
.main-layout-content {
  max-width: 1000px;
  margin: 0;
}
</style>