<template>
  <div id="main-layout-sidebar-conference-statistics">
    <h2>{{ "HeaderStatistics" | language("sidebar") }}</h2>
    <div class="scientific-degree-table">
      <h5>{{ "ScientificDegree" | language("sidebar") }}</h5>
      <table>
        <tr>
          <td class="td-color" align="center">
            <div style="background-color: #ff0000"></div>
          </td>
          <td class="td-content" align="left">
            {{ "DoctorOfScience" | language("sidebar") }}
          </td>
          <td class="td-count">16</td>
        </tr>
        <tr>
          <td class="td-color" align="center">
            <div style="background-color: #ff6600"></div>
          </td>
          <td class="td-content" align="left">
            {{ "CandidateOfScience" | language("sidebar") }}
          </td>
          <td class="td-count">15</td>
        </tr>
        <tr>
          <td class="td-color" align="center">
            <div style="background-color: #ff9900"></div>
          </td>
          <td class="td-content" align="left">
            {{ "РostgraduateStudent" | language("sidebar") }}
          </td>
          <td class="td-count">8</td>
        </tr>
        <tr>
          <td class="td-color" align="center">
            <div style="background-color: #ffcc00"></div>
          </td>
          <td class="td-content" align="left">
            {{ "WithoutDegree" | language("sidebar") }}
          </td>
          <td class="td-count">39</td>
        </tr>
      </table>
    </div>
    <div class="academic-rank-table">
      <h5>{{ "AcademicRank" | language("sidebar") }}</h5>
      <table>
        <tr>
          <td class="td-color" align="center">
            <div style="background-color: #3300ff"></div>
          </td>
          <td class="td-content" align="left">
            {{ "AcademicianOfRAS" | language("sidebar") }}
          </td>
          <td class="td-count">4</td>
        </tr>
        <tr>
          <td class="td-color" align="center">
            <div style="background-color: #3333ff"></div>
          </td>
          <td class="td-content" align="left">
            {{ "CorrespondingMemberOfRAS" | language("sidebar") }}
          </td>
          <td class="td-count">0</td>
        </tr>
        <tr>
          <td class="td-color" align="center">
            <div style="background-color: #3366ff"></div>
          </td>
          <td class="td-content" align="left">
            {{ "ProfessorOfRAS" | language("sidebar") }}
          </td>
          <td class="td-count">0</td>
        </tr>
        <tr>
          <td class="td-color" align="center">
            <div style="background-color: #3399ff"></div>
          </td>
          <td class="td-content" align="left">
            {{ "Professor" | language("sidebar") }}
          </td>
          <td class="td-count">12</td>
        </tr>
        <tr>
          <td class="td-color" align="center">
            <div style="background-color: #33ccff"></div>
          </td>
          <td class="td-content" align="left">
            {{ "AssociateProfessor" | language("sidebar") }}
          </td>
          <td class="td-count">9</td>
        </tr>
        <tr>
          <td class="td-color" align="center">
            <div style="background-color: #33ffff"></div>
          </td>
          <td class="td-content" align="left">
            {{ "SeniorScientistResearcher" | language("sidebar") }}
          </td>
          <td class="td-count">1</td>
        </tr>
        <tr>
          <td class="td-color" align="center">
            <div style="background-color: #ff00ff"></div>
          </td>
          <td class="td-content" align="left">
            {{ "WithoutRank" | language("sidebar") }}
          </td>
          <td class="td-count">52</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
#main-layout-sidebar-conference-statistics {
  /* margin-top: 35px; */
}

table {
  width: 100%;
}
td {
  border: 1px solid var(--main-text-color);
  font-size: 14px;
}
.td-color {
  width: 23px;
}
.td-color div {
  width: 16px;
  height: 16px;
}
.td-content {
  width: auto;
  padding: 0 5px;
}
.td-count {
  width: 28px;
}

.academic-rank-table {
  margin-top: 15px;
}
</style>