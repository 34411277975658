export default {
  actions: {
    changeLanguage({ commit }, lang) {
      commit('updateLanguage', lang);
    },
  },
  mutations: {
    updateLanguage(state, lang) {
      state.language = lang
    },
  },
  state: {
    // язык по умолчанию
    // так:
    //language: 'en',
    // или так:
    language: 'ru',
    // список доступных языков
    // так:
    //languages: { en: 'English', ru: 'Русский' },
    // или так:
    languages: { ru: 'Русский' },
  },
  getters: {
    getLanguage(state) {
      return state.language
    },
    getLanguages(state) {
      return state.languages
    },
  },
}
