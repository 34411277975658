<template>
  <div id="main-layout-footer">
    <div>{{ "organization-rights" | language("footer") }} {{ thisYear }}</div>
  </div>
</template>

<script>
export default {
  computed: {
    thisYear() {
      return new Date().getFullYear();
    },
  },
};
</script>