<template>
  <div id="main-layout-sidebar">
    <SideBarMenu />
    <SideBarConferenceStatistics />
    <SideBarGeography />
  </div>
</template>

<script>
import SideBarMenu from "@/components/SideBar/SideBarMenu";
import SideBarConferenceStatistics from "@/components/SideBar/SideBarConferenceStatistics";
import SideBarGeography from "@/components/SideBar/SideBarGeography";

export default {
  components: {
    SideBarMenu,
    SideBarConferenceStatistics,
    SideBarGeography,
  },
};
</script>

<style scoped>
#main-layout-sidebar {
  padding: 0 18px;
  background-color: var(--main-bg-color);
  height: 100%;
}
#main-layout-sidebar div {
  padding: 25px 0 10px 0;
}
</style>