import Vue from 'vue'
import Vuex from 'vuex'
import main from './modules/main'
import links from './modules/links'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    main,
    links,
  }
})
