<template>
  <img :src="require(`@/assets/header_banner/${bgBanner}`)" alt="banner" />
</template>

<script>
import languageFilter from "@/filters/language.filter";

export default {
  name: "header-banner",
  computed: {
    bgBanner() {
      return languageFilter("desktop-banner", "headerBanner");
    },
  },
};
</script>

<style scoped>
img {
  width: 100%;
  height: auto;
}
</style>