<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import ManageLayout from "@/layouts/ManageLayout";
import RegisterLayout from "@/layouts/RegisterLayout";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    MainLayout,
    ManageLayout,
    RegisterLayout,
  },
  computed: {
    ...mapGetters(["getLanguage", "getLanguages"]),
    layout() {
      return this.$route.meta.layout || "main-layout";
    },
  },
  methods: {
    ...mapActions(["changeLanguage"]),
  },
  mounted() {
    // установка последнего выбранного языка из localstorage
    if (localStorage.getItem("lang")) {
      // если выбранный ранее язык недоступен, то ничего не делаем и показываем дефолтный язык
      if (!this.getLanguages[localStorage.getItem("lang")]) {
        return;
      }
      // установка языка из localstorage в <html lang>
      document.documentElement.setAttribute(
        "lang",
        localStorage.getItem("lang")
      );
      // смена языка
      this.changeLanguage(localStorage.getItem("lang"));
    }
  },
};
</script>

<style>
:root {
  --accent-color: #1e90ff;
  --second-accent-color: #08c932;
  --main-text-color: #333;
  --main-bg-color: rgb(245, 245, 245);
  --footer-bg-color: rgb(190, 190, 190);
  --default-indent: 16px;
  --default-radius: 5px;
  --default-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--main-text-color);
}

#app h2 {
  font-size: 18px;
  font-weight: bold;
}
#app h5 {
  font-size: 16px;
  text-align: left;
  line-height: 24px;
  font-weight: bold;
  margin-top: 15px;
}
#app .nodecoration-icon-link {
  text-decoration: none;
  display: grid;
  /* grid-auto-flow: column; */
  grid-template: 1fr / auto 1fr;
}
#app section.view-section {
  margin: 25px 0;
  padding: var(--default-indent);
  box-shadow: var(--default-shadow);
  border-radius: var(--default-radius);
}
#app section.view-section .view-section-subsections {
  margin: 25px 0;
}
#app section.view-section .view-section-subsections div:first-child {
  margin: 0;
}
#app section.view-section .view-section-subsections div:last-child {
  margin: 25px 0;
  border-bottom: none;
}
.view-section-child {
  margin: 25px 0;
  border-bottom: 1px solid var(--main-bg-color);
}
.text-center,
#app .text-center h5 {
  text-align: center;
}
.bold {
  font-weight: bold;
}
#app .default-indent-bottom,
#app .default-indent-bottom li {
  margin-bottom: var(--default-indent);
}

#top-navigation a,
#nav-bar a,
#main-layout-nav-collapse a {
  text-decoration: none;
  color: var(--main-text-color);
}
#top-navigation a.router-link-exact-active,
#nav-bar a.router-link-exact-active,
#main-layout-nav-collapse a.router-link-exact-active {
  color: var(--accent-color);
}

#app .mdi::before {
  font-size: 20px;
  line-height: 14px;
  color: var(--main-text-color);
  padding-right: 5px;
}

#app .main-layout-pages-h2 {
  border-bottom: 1px solid var(--main-bg-color);
  padding: 10px 0;
}
#app .list-style-icon li {
  padding: 0;
  list-style-type: none;
}
#app .list-style-icon li i {
  margin-left: -25px;
  font-size: 10px;
}
#app .accent-block {
  border-left: 3px solid var(--second-accent-color);
  padding: 16px;
}
#app .timestamp {
  font-weight: 600;
  font-style: italic;
}
#main-layout-organizers-page .list-style-icon li i {
  margin-left: -18px;
}
#app .list-style-icon .mdi::before {
  font-size: 14px;
}
#app .list-style-none {
  padding: 0;
  list-style-type: none;
}

#app .list-table {
  width: 100%;
}
#app .list-table td {
  display: inline-block;
  vertical-align: top;
}
#app #main-layout-footer {
  display: inline-block;
  width: 100%;
  padding: 25px;
  background-color: var(--footer-bg-color);
  font-size: 12px;
}

#app .text-align-justify {
  text-align: justify;
}

#coference-qrcode {
  width: 290px;
  height: auto;
  margin: 25px 0;
}

#coference-pic {
  width: 100%;
  height: auto;
  margin: 25px 0;
}
</style>
